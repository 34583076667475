import { observable, action, computed, autorun, toJS, reaction } from 'mobx';
import _ from 'lodash'
import {AUD,shorterAUD} from '@src/utils/currency'
class FilterStore {
    constructor(appStore,uiStore) {
        this.appStore = appStore;
        this.uiStore = uiStore
        this._init()
    }

    _init = async ()=>{
        await this._getAndSetPropertyFeatures()
        await this._getAndSetPropertyTypes()
    }


    _getAndSetPropertyFeatures = async ()=>{
        const res = await this.appStore.clientApi.codeTable_('PROTY_FEAT')
        this.setFilterFeature(this.preprocessFeatures(res))
    }
    _getAndSetPropertyTypes = async ()=>{
        const res = await this.appStore.clientApi.codeTable_('PROTY_TYPE')
        // console.log(res)
        this.setFilterPropertyTypes(this.preprocessPropertyTypes(res))
    }
    preprocessFeatures = (rawData)=>{
        return rawData.map(item=>{
            return {
                ...item,
                title:item.name,
                value:item.code
            }
        })
    }
    preprocessPropertyTypes = (rawData)=>{
        let list = [
            // {
            //     title:'Any',
            //     value:null
            // }
        ]
        rawData.forEach(item=>{
            list.push({
                ...item,
                title:item.name,
                value:item.code
            })
        })
        return list
    }

    @observable onClickSearchTriggerNumber = 0
    @action setOnClickSearchTriggerNumber = value=>{
        this.onClickSearchTriggerNumber = value
    }

    triggerSearch=()=>{
        // console.log('triggerSearch');
        this.setOnClickSearchTriggerNumber(this.onClickSearchTriggerNumber+1)
    }

    getRentPriceRange = ()=>{
        let list = [
            {
                title:"Any",
                value:'any'
            }
        ]
        let currentPrice = 50
        while (currentPrice < 1000) {
            list.push({
                title:shorterAUD(currentPrice),
                value:currentPrice
            })
            currentPrice += 50
        }

        while (currentPrice < 2000) {
            list.push({
                title:shorterAUD(currentPrice),
                value:currentPrice
            })
            currentPrice += 100
        }
        while (currentPrice <= 5000) {
            list.push({
                title:shorterAUD(currentPrice),
                value:currentPrice
            })
            currentPrice += 500
        }
        return list
    }

    getBuyPriceRange = ()=>{
        let list = [{
                title:"Any",
                value:'any'
            }
        ]
        let currentPrice = 50000
        while (currentPrice < 1000000) {
            list.push({
                title:shorterAUD(currentPrice),
                value:currentPrice
            })
            currentPrice += 50000
        }

        while (currentPrice < 2000000) {
            list.push({
                title:shorterAUD(currentPrice),
                value:currentPrice
            })
            currentPrice += 100000
        }
        while (currentPrice < 3000000) {
            list.push({
                title:shorterAUD(currentPrice),
                value:currentPrice
            })
            currentPrice += 250000
        }
        while (currentPrice < 5000000) {
            list.push({
                title:shorterAUD(currentPrice),
                value:currentPrice
            })
            currentPrice += 500000
        }
        while (currentPrice < 10000000) {
            list.push({
                title:shorterAUD(currentPrice),
                value:currentPrice
            })
            currentPrice += 1000000
        }
        while (currentPrice <= 16000000) {
            list.push({
                title:shorterAUD(currentPrice),
                value:currentPrice
            })
            currentPrice += 2000000
        }
        return list
    }

    RENT_PRICE_RANGE = this.getRentPriceRange()
    

    BUY_PRICE_RANGE = this.getBuyPriceRange()
    

    // Property filter

    @observable filterPropertyTypes = []

    @action setFilterPropertyTypes = (value)=> this.filterPropertyTypes = value
    FILTER_BUY_RENT_TYPE = [
        {
            title:'Buy new',
            value:"newDevelopment",
        },
        {
            title:'Buy existing',
            value:"buyExisting",
        },
        {
            title:'Rent',
            value:"rent",
        },
    ]

    @computed get currentFilterType(){
        if(this.isBuyNew  && !this.isRentFilter){
            return 'newDevelopment'
        }else if (!this.isBuyNew  && !this.isRentFilter) {
            return 'buyExisting'
        }else if(!this.isBuyNew  && this.isRentFilter){
            return 'rent'
        }
    }

    @action setCurrentFilterType=(type)=>{
        if(type==='newDevelopment'){
            this.isBuyNew = true
            this.setIsRentFilter(false)
        }else if (type==='buyExisting'){
            this.isBuyNew = false
            this.setIsRentFilter(false)
        }else if (type==='rent'){
            this.isBuyNew = false
            this.setIsRentFilter(true)
        }
    }

    FILTER_HOME_TYPE = [
        {
            title:'All type',
            value:null
        },
        {
            title:"Single room",
            value:"singleRoom"
        },
        {
            title:'Entire house',
            value:'whole'
        }
    ]

    @observable filterFeature = []

    @action setFilterFeature=(value)=>this.filterFeature = value
    @computed get buyFeatures(){
        return this.filterFeature.filter(item=>item.flag && item.flag.includes('buy'))
    }
    @computed get rentFeatures(){
        return this.filterFeature.filter(item=>item.flag && item.flag.includes('rent'))
    }

    FILTER_BEDROOMS=[
        {
            title:'Studio',
            value:0,
            smallest:true
        },
        {
            title:'1',
            value:1,
        },
        {
            title:'2',
            value:2,
        },
        {
            title:'3',
            value:3,
        },
        {
            title:'4',
            value:4,
        },
        {
            title:'5+',
            value:5,
            largerThan:true
        }
    ]

    FILTER_BATHROOMS = [
        {
            title:'1',
            value:1,
            smallest:true
        },
        {
            title:'2',
            value:2,
        },
        {
            title:'3',
            value:3,
        },
        {
            title:'4',
            value:4,
        },
        {
            title:'5',
            value:5
        },
        {
            title:'6+',
            value:6,
            largerThan:true
        }
    ]

    FILTER_PARKINGS = [
        {
            title:'0',
            value:0,
            smallest:true
        },
        {
            title:'1',
            value:1,
        },
        {
            title:'2',
            value:2,
        },
        {
            title:'3',
            value:3,
        },
        {
            title:'4',
            value:4
        },
        {
            title:'5+',
            value:5,
            largerThan:true
        }
    ]

    FILTER_SORT_ORDER = [
        // {
        //     title:'Featured',
        //     value:'featured'
        // },
        {
            title:'Newest in market',
            value:'newest'
        },
        {
            title:'Lowest price',
            value:'lowestPrice'
        },
        {
            title:'Highest price',
            value:'highestPrice'
        },
        {
            title:'Earliest inspection',
            value:'earliestInspection'
        },
        // {
        //     title:'Suburb',
        //     value:'suburb'
        // }
    ]

    FILTER_ASPECT=[
        {
            title:'Any',
            value:null
        },
        {
            title:'N',
            value:'N',
        },
        {
            title:'W',
            value:'W',
        },
        {
            title:'S',
            value:'S',
        },
        {
            title:'E',
            value:'E',
        },
        {
            title:'N+E',
            value:'NE',
        },
        {
            title:'N+W',
            value:'NW',
        },
        {
            title:'S+E',
            value:'SE',
        },
        {
            title:'S+W',
            value:'SW',
        },
    ]



    @observable openIndex = null

    @action setOpenIndex = value=>this.openIndex = value

    toggleFilterSettingChip = (index)=>{
        this.setOpenIndex(index||null)
    }

    @observable inspectionAuctionFilterChecked=false
    @action setInspectionAuctionFilterChecked = value=>this.inspectionAuctionFilterChecked = value

    @observable excludeUnderOffer=false
    @action setExcludeUnderOffer = value=>this.excludeUnderOffer = value

    @observable excludeOffThePlan=false
    @action setExcludeOffThePlan = value=>this.excludeOffThePlan = value

    @observable inspectionOrAuction=false
    @action setInspectionOrAuction = value=>this.inspectionOrAuction = value

    //buy price range
    @observable priceRange={
        min:null,
        max:null
    }
    @action setPriceRange = value=>this.priceRange = value
    
    @observable rentPriceRange={
        min:null,
        max:null
    }
    @action setRentPriceRange = value=>this.rentPriceRange = value

    @observable landSizeRange={
        min:null,
        max:null
    }
    @action setLandSizeRange = value=>this.landSizeRange = value

    @observable bathrooms = {
        from:1,
        to:6
    }
    @action setBathRooms = value=>this.bathrooms = value
    @observable bedrooms = {
        from:0,
        to:5
    }
    @action setBedrooms = value=>this.bedrooms = value

    @observable parkings = {
        from:0,
        to:5
    }
    @action setParkings = value=>this.parkings = value

    @observable features = []
    @action setFeatures = value=>this.features = value

    @observable propertyType = []
    @action setPropertyType = value =>this.propertyType = value

    @observable rentType = null
    @action setRentType = value =>this.rentType = value

    //Date ISO string
    @observable rentAvailableFrom = null
    @action setRentAvailableFrom = value=>this.rentAvailableFrom = value

    //filter ref
    @observable filterBottomRef = null
    @action setFilterBottomRef = value => this.filterBottomRef = value
    
    @observable isRentFilter = false
    @action setIsRentFilter = value =>{
        // console.log(value)
        this.isRentFilter = value
    }

    @observable isBuyNew = true
    @action setIsBuyNew = value =>{
        // console.log(value)
        this.isBuyNew = value
    }

    
    @observable listViewSortBy = 'newest'
    @action setListViewSortBy = value =>this.listViewSortBy = value

    @computed get listViewSortByText(){
        return _.find(this.FILTER_SORT_ORDER,{value:this.listViewSortBy}).title
    }

    @observable searchAddress= ''
    @action setSearchAddress = value=>this.searchAddress = value

    @observable searchList = []
    @action setSearchList = value=>this.searchList = value

    
    openFilter = ()=>{
        // bottom sheet bug. dirty solution
        //https://github.com/osdnk/react-native-reanimated-bottom-sheet/issues/211
        // issue fixed in new version 
        // this.filterBottomRef.snapTo(0)
        this.filterBottomRef.snapTo(0)
    }
    closeFilter = ()=>{
        // this.filterBottomRef.snapTo(1)
        this.filterBottomRef.snapTo(1)

        this.setShowCollectionFilter(false)
    }

    clearFilter=()=>{
        this.setRentAvailableFrom(null)
        this.setRentType(null)
        this.setPropertyType([])
        this.setParkings({
            from:0,
            to:5
        })
        this.setFeatures([])
        this.setBathRooms({
            from:1,
            to:6
        })
        this.setBedrooms({
            from:0,
            to:5
        })
        this.setPriceRange({
            min:null,
            max:null
        })
        this.setRentPriceRange({
            min:null,
            max:null
        })
        this.setLandSizeRange({
            min:null,
            max:null
        })
        this.setExcludeUnderOffer(false)
        this.setExcludeOffThePlan(false)
        this.setSearchAddress('')
        this.setSearchList([])
    }

    @computed get isFilterCleared(){
        const commonCondition = this.propertyType.length===0 &&
                                this.parkings.from===0 && this.parkings.to === 5 && this.features.length===0 &&
                                this.bathrooms.from===1 && this.bathrooms.to === 6 &&
                                this.bedrooms.from === 0 && this.bedrooms.to === 5 &&
                                this.searchAddress === '' && this.searchList.length === 0
        if(this.isRentFilter){
            return commonCondition && this.rentAvailableFrom===null && this.rentType===null && this.rentPriceRange.min === null && this.rentPriceRange.max === null
        }else{
            return commonCondition && this.excludeUnderOffer===false && this.excludeOffThePlan===false && this.priceRange.min === null && this.priceRange.max === null
        }
    }

    @computed get filterObject(){
        const formatRange=(rangeObj,FILTER_OPTIONS)=>{
            let range={
                from:rangeObj.from,
                to:_.find(FILTER_OPTIONS,{value:rangeObj.to})?.largerThan?null:rangeObj.to
            }
            return range
        }
        const parkings = formatRange(this.parkings,this.FILTER_PARKINGS)
        const bedrooms = formatRange(this.bedrooms,this.FILTER_BEDROOMS)
        const bathrooms = formatRange(this.bathrooms,this.FILTER_BATHROOMS)
        const priceRange = {
            from:this.isRentFilter?this.rentPriceRange.min:this.priceRange.min,
            to:this.isRentFilter?this.rentPriceRange.max:this.priceRange.max
        }
        const landSizeRange = {
            from:this.landSizeRange.min,
            to:this.landSizeRange.max
        }                
        if(this.isRentFilter){
            return {
                search:this.searchAddress,
                postcode:this.searchList,
                propertyType:this.propertyType,
                listingType:'lease',
                rentType:this.rentType,
                parkings,
                bedrooms,
                priceRange,
                landSizeRange,
                bathrooms,
                features:this.features,
                available:this.rentAvailableFrom,
                orderBy:this.listViewSortBy
            }
        }else{
            return {
                search:this.searchAddress,
                postcode:this.searchList,
                propertyType:this.propertyType,
                listingType:'sale',
                listingCategory:this.isBuyNew?100:200,
                features:this.features,
                parkings,
                bedrooms,
                priceRange,
                landSizeRange,
                bathrooms,
                excludeUnderOffer:this.excludeUnderOffer,
                excludeOffThePlan:this.excludeOffThePlan,
                hasInspectionOrAuction:this.inspectionAuctionFilterChecked,
                orderBy:this.listViewSortBy
            }
        }
    }

    @computed get actFilterSettings(){
        let settings = []
        settings.push(
            {
                title:!this.isRentFilter?this.isBuyNew?'Buy new':'Buy existing':'Rent',
                value:'',
                type:'itemSelector',
                effecting:true,
                key:'isRentFilter',
                // controls:{
                //     options:this.FILTER_BUY_RENT_TYPE,
                //     value:!this.isRentFilter?'buy':'rent',
                //     onChange:this.setIsRentFilter
                // }
                
            }
        )
        const bedRoomsTitle = ()=>{
            if(this.bedrooms.from!==this.bedrooms.to){
                if(this.bedrooms.from === 0 && this.bedrooms.to === 5){
                    return `Beds`
                }else if(this.bedrooms.to === 5){
                    return `${this.bedrooms.from}+ beds`
                }else if(this.bedrooms.from === 0){
                    return `Under ${this.bedrooms.to} ${this.bedrooms.from===1?'bed':'beds'}`
                }else{
                    return `${this.bedrooms.from}-${this.bedrooms.to} beds`
                }
            }else{
                if(this.bedrooms.from===5){
                    return `5+ beds`
                }else{
                    return `${this.bedrooms.from===0?'Studio':this.bedrooms.from===1?'1 bed':`${this.bedrooms.from} beds`}`
                }
            }
        }
        settings.push(
            {
                title:bedRoomsTitle(),
                value:this.bedrooms,
                type:'multiButton',
                key:'bedrooms',
                effecting:bedRoomsTitle()!=='Beds',
                controls:{
                    options:this.FILTER_BEDROOMS,
                    value:this.bedrooms,
                    onChange:this.setBedrooms,
                    clearTitle:'Any bedrooms'
                }
            }
        )

        const priceRangeTitle = (range)=>{
            if(range.max && range.min===null){
                return `Under ${shorterAUD(range.max)}`
            }else if(range.min&& range.max===null){
                return `Over ${shorterAUD(range.min)}`
            }else if(range.min&& range.max){
                return `${shorterAUD(range.min)} - ${shorterAUD(range.max)}`
            }else{
                return `Price`
            }
        }
        const priceRange = this.isRentFilter?this.rentPriceRange:this.priceRange
        const title = priceRangeTitle(priceRange)
        settings.push(
            {
                title:title,
                value:priceRange,
                type:'priceRange',
                key:'priceRange',
                effecting:title!=='Price',
                controls:{
                    options:null,
                    value:priceRange,
                    onChange:this.isRentFilter?this.setRentPriceRange:this.setPriceRange
                }
            }
        )
    
        return settings
    }


    // project filter settings
    @observable unitBathrooms = {
        from:1,
        to:6
    }
    @action setUnitBathrooms = value=>this.unitBathrooms = value

    @observable unitParkings = {
        from:0,
        to:5
    }
    @action setUnitParkings = value=>this.unitParkings = value

    @observable unitPriceRange={
        min:null,
        max:null
    }
    @action setUnitPriceRange = value=>this.unitPriceRange = value

    @observable unitStage = null
    @action setUnitStage = value=>this.unitStage = value

    @observable unitBuilding = null
    @action setUnitBuilding = value=>this.unitBuilding = value

    @observable unitLevel = null
    @action setUnitLevel = value=>this.unitLevel = value

    @observable unitAspect = []
    @action setUnitAspect = value=>this.unitAspect = value

    @observable unitHasMediaOrStudy = false
    @action setHasUnitMediaOrStudy = value=>this.unitHasMediaOrStudy = value

    @observable unitHasStorage = false
    @action setHasUnitStorage = value=>this.unitHasStorage = value

    @observable unitAdaptable = false
    @action setUnitAdaptable = value=>this.unitAdaptable = value

    @observable unitLevelFilterOptions = [{
        title:'Any level',
        value:null
    }]
    @action setUnitLevelFilterOptions = value=>this.unitLevelFilterOptions = value

    addUnitLevelFilterOptions = (levels)=>{
        let newLevels = [
            {
                title:'Any level',
                value:null
            }
        ]
        levels.filter(o=>!_.isNil(o)).forEach(level=>{
            newLevels.push({
                title:String(level),
                value:String(level)
            })
        })
        newLevels = _.uniqBy(newLevels,o=>o.value)
        this.setUnitLevelFilterOptions(newLevels)
    }

    clearUnitLevelFilterOptions =()=>{
        this.addUnitLevelFilterOptions([])
    }

    @observable unitBuildingFilterOptions =[]
    @action setUnitBuildingFilterOptions = value=>this.unitBuildingFilterOptions = value
    
    addUnitBuildingFilterOptions = (buildings)=>{
        let newBuildings = [{
            title:'Any building',
            value:null
        }]
        buildings.filter(o=>!_.isNil(o)).forEach(building=>{
            newBuildings.push({
                title:String(building),
                value:String(building)
            })
        })
        newBuildings = _.uniqBy(newBuildings,o=>o.value)
        this.setUnitBuildingFilterOptions(newBuildings)
    }

    clearUnitBuildingFilterOptions =()=>{
        this.addUnitBuildingFilterOptions([])
    }

    @observable unitStageFilterOptions =[]
    @action setUnitStageFilterOptions = value=>this.unitStageFilterOptions = value
    
    addUnitStageFilterOptions = (stages)=>{
        let newStages = [{
            title:'Any stages',
            value:null
        }]
        stages.filter(o=>!_.isNil(o)).forEach(building=>{
            newStages.push({
                title:String(building),
                value:String(building)
            })
        })
        newStages = _.uniqBy(newStages,o=>o.value)
        this.setUnitStageFilterOptions(newStages)
    }

    clearUnitStageFilterOptions =()=>{
        this.addUnitStageFilterOptions([])
    }


    clearUnitFilter = ()=>{
        this.setUnitBathrooms({
            from:1,
            to:6
        })
        this.setUnitParkings({
            from:0,
            to:5
        })
        this.setUnitPriceRange({
            min:null,
            max:null
        })
        this.setUnitStage(null)
        this.setUnitBuilding(null)
        this.setUnitLevel(null)
        this.clearUnitLevelFilterOptions()
        this.setUnitAspect([])
        this.setHasUnitMediaOrStudy(false)
        this.setHasUnitStorage(false)
        this.setUnitAdaptable(false)
    }

    inSliderRange = (number,range,filterOptions)=>{
        // const fromOption = _.find(filterOptions,{value:range.from})
        const toOption = _.find(filterOptions,{value:range.to})
        if(toOption && toOption.largerThan){
            return range.from <= number
        }else if (toOption && !toOption.largerThan){
            return range.from <= number && number <= toOption.value
        }else{
            return false
        }
    }

    inPriceRange = (price,priceRange)=>{
        if(_.isNil(priceRange.min) && _.isNil(priceRange.max)){
            return true
        }else{
            return priceRange.min <= price && price <=priceRange.max
        }
    }

    unitFilterMatch=(unit)=>{
        if(
            this.inSliderRange(unit.processedData.bathrooms,this.unitBathrooms,this.FILTER_BATHROOMS) &&
            this.inSliderRange(unit.processedData.carspaces,this.unitParkings,this.FILTER_PARKINGS) &&
            (!_.isNil(this.unitPriceRange.min) && !_.isNil(this.unitPriceRange.max))? !_.isNil(unit.processedData.price) && this.inPriceRange(unit.processedData.price,this.unitPriceRange):true && 
            (_.isNil(this.unitStage)?true:this.unitStage === String(unit.rawData.stage)) &&
            (_.isNil(this.unitBuilding)?true:this.unitBuilding === String(unit.processedData.address.building)) &&
            (_.isNil(this.unitLevel)?true:this.unitLevel === String(unit.processedData.address.level)) &&
            (this.unitAspect.length===0?true:this.unitAspect.includes(unit.processedData.aspect)) && 
            (this.unitHasMediaOrStudy===false?true: Boolean(unit.processedData.mediaOrStudy) === this.unitHasMediaOrStudy) &&
            (this.unitAdaptable===false?true: Boolean(unit.processedData.adaptable) === this.unitAdaptable) &&
            (this.unitHasStorage===false?true: Boolean(unit.processedData.storage) === this.unitHasStorage) 
            ){
            return true
        }
        return false
    }

    @observable showCollectionFilter = false
    @action setShowCollectionFilter = value => this.showCollectionFilter = value

    showCollectionFilterView = () => {
        this.setShowCollectionFilter(true)

        this.filterBottomRef.snapTo(0)
        this.filterBottomRef.snapTo(0)
    }

    @observable collectionPropertyType = []
    @action setCollectionPropertyType = value => this.collectionPropertyType = value


    generateBathroomTitle = (bathrooms)=>{
        if(bathrooms.from!==bathrooms.to){
            if(bathrooms.from === 1 && bathrooms.to === 6){
                return `Any`
            }else if(bathrooms.to === 6){
                return `${bathrooms.from}+ bathrooms`
            }else if(bathrooms.from === 0){
                return bathrooms.to === null ? 'Any' : `Under ${bathrooms.to} ${bathrooms.from===1?'bathroom':'bathrooms'}`
            }else{
                if (bathrooms.to === null) {
                    return `${bathrooms.from}+ bathrooms`
                } else {
                    return `${bathrooms.from}-${bathrooms.to} bathrooms`
                }
            }
        }else{
            if(bathrooms.from===6){
                return `6+ bathrooms`
            }else{
                return `${bathrooms.from===1?'1 bathroom':`${bathrooms.from} bathrooms`}`
            }
        }
    }

    generateBedroomTitle = (bedrooms)=>{
        if(bedrooms.from!==bedrooms.to){
            if(bedrooms.from === 0 && bedrooms.to === 5){
                return `Any`
            }else if(bedrooms.to === 5){
                return `${bedrooms.from}+ bedrooms`
            }else if(bedrooms.from === 0){
                return bedrooms.to === null ? 'Studio' : `Under ${bedrooms.to} ${bedrooms.from===1?'bedroom':'bedrooms'}`
            }else{
                if (bedrooms.to === null) {
                    return `${bedrooms.from}+ bedrooms`
                } else {
                    return `${bedrooms.from}-${bedrooms.to} bedrooms`
                }
            }
        }else{
            if(bedrooms.from===5){
                return `5+ bedrooms`
            }else{
                return `${bedrooms.from===0?'Studio':bedrooms.from===1?'1 bedroom':`${bedrooms.from} bedrooms`}`
            }
        }
    }

    generateParkingsTitle = (parkings)=>{
        if(parkings.from!==parkings.to){
            if(parkings.from === 0 && parkings.to === 5){
                return `Any`
            }else if(parkings.to === 5){
                return `${parkings.from}+ parkings`
            }else if(parkings.from === 0){
                return parkings.to === null ? 'Any' : `Under ${parkings.to} ${parkings.from===1?'carspace':'parkings'}`
            }else{
                if (parkings.to === null) {
                    return `${parkings.from}+ parkings`
                } else {
                    return `${parkings.from}-${parkings.to} parkings`
                }
            }
        }else{
            if(parkings.from===5){
                return `5+ parkings`
            }else{
                return `${parkings.from===1?'1 carspace':`${parkings.from} carspaces`}`
            }
        }
    }

    @action setFilter = (filter) => {
        this.searchAddress = filter.search
        this.propertyType = filter.propertyType
        //this.isRentFilter = (filter.listingType === 'rent')
        this.isRentFilter = (filter.listingType === 'rent')
        this.isBuyNew = filter.listingCategory === 100
        this.features = filter.features

        if (filter.parkings.to === null) {
            filter.parkings.to = 5
        }

        this.parkings = filter.parkings
        
        if (filter.bedrooms.to === null) {
            filter.bedrooms.to = 5
        }

        this.bedrooms = filter.bedrooms


        this.priceRange = {
            min: filter.priceRange.from,
            max: filter.priceRange.to,
        }
        this.landSizeRange = {
            min: filter.landSizeRange.from,
            max: filter.landSizeRange.to,
        }

        if (filter.bathrooms.to === null) {
            filter.bathrooms.to = 6
        }

        this.bathrooms = filter.bathrooms
        this.excludeUnderOffer = filter.excludeUnderOffer
        this.excludeOffThePlan = filter.excludeOffThePlan
        this.orderBy = filter.orderBy
    }

    @observable closeFilterTrigger = 1
    @action closeFilter = ()=>this.closeFilterTrigger += 1

}

export default FilterStore;
