import { observable, action, computed, autorun, toJS } from 'mobx';
import { DateTime } from 'luxon'
import { clone } from '../../utils/typeCheck'
import _ from 'lodash'
import { dateParse,daysToNow } from '../../utils/timeParse';
class PropertyStore {
    constructor(generalInspectionStore) {
        this.generalInspectionStore = generalInspectionStore
    }

    defaultBanner =[
        { 
            "placeholder":require('../../images/no-conversation-data-available.png'),
        }
    ]
    
    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    openInspectionString = (timeList)=>{
        if(timeList.length > 0){
            return `${DateTime.local().toFormat('yyyy-MM-dd')===DateTime.fromISO(timeList[0].details.ISODateString).toFormat('yyyy-MM-dd')?'Today':timeList[0].date} ${timeList[0].time}`
        }
    }

    isProject = (rawData)=>{
        return rawData.project === undefined
    }

    processListData = (rawData)=>{
        const parsedRes = rawData.map(this.processData)
        return parsedRes
    }

    processData = (rawData)=>{
        if(this.isProject(rawData)){
            return this.processProjectDetailResults(rawData)
        }else{
            return this.processPropertyDetailResults(rawData)
        }
    }

    mergeAvailableInspectionTime=(processedData,inspectionTimes)=>{
        let clonedProcessedData = clone(processedData)
        clonedProcessedData.processedData.nextAvailableInspectionTime = this.openInspectionString(inspectionTimes)
        clonedProcessedData.processedData.availableInspectionTimes = inspectionTimes
        return clonedProcessedData
    }

    processBanners = (resources = [])=>{
        return _.sortBy(resources.filter(item=>(item.type==='IMAGE' && item.mediaTag!=='LOGO')||item.type==='VIDEO'),item=>(!Boolean(item.isCover) && item.order)).map(item=>{
            if(item.type==='IMAGE'){
                return { 
                    "thumbnailMobile":item.thumbnail || item.urlM || item.url,
                    'url':item.url,
                    'urlM':item.urlM,
                    'thumbnail':item.thumbnail
                }
            }else{
                return {
                    "video":item.url,
                }
            }
        })
    }

    processProjectDetailResults = (raw)=>{
        const listing = raw[':listing']
        const address = raw[':address']
        const features = raw[':features'] || {}
        const sellingPoints = listing[':sellingPoints'] || []

        let banners = (listing[':resourceGroup'] && listing[':resourceGroup'][':resources']) ? this.processBanners(listing[':resourceGroup'][':resources']):[]
        banners = banners.length===0?this.defaultBanner:banners
        // banners = [...banners,{video:'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4'}]
        const videoIndex = _.findIndex(banners,'video')
        if(videoIndex>=0){
            let reorderBanners = banners.filter(banner=>!banner.video)
            reorderBanners.splice(1,0,banners[videoIndex])
            banners = reorderBanners
        }

        const assignedAgents = listing[':assignedAgents'] && listing[':agency'] ?listing[':assignedAgents'].filter(agent=>Boolean(agent.isListed)).map(item=>{
            item[':agent'][':agency'] = listing[':agency']
            return item[':agent']
        }):[]
        
        let processedUnits = raw[':units'].map(unit=>this.processPropertyDetailResults(unit))
        //pass project assignedAgents to units
        processedUnits.forEach(unit=>{
            unit.processedData.assignedAgents = assignedAgents
        })
        const parsedOpenInspections = listing[':openInspections']? this.generalInspectionStore.generateOpenInspectionTimeList(listing[':openInspections']):[]
        // console.log(parsedOpenInspections)
        const hasLargerThanThree = (raw[':propertyOptions'] && raw[':propertyOptions']['FLOOR_PLAN'])?raw[':propertyOptions']['FLOOR_PLAN'].some(unit=> {
            const value = typeof unit.BEDS === 'string'? Number(unit.BEDS):unit.BEDS
            return value > 3
        }):false
        // console.log(hasLargerThanThree,raw[':propertyOptions']['FLOOR_PLAN'].map(unit=> typeof unit.BEDS === 'string'? Number(unit.BEDS):unit.BEDS).sort())
        let bedsLine = (raw[':propertyOptions'] && raw[':propertyOptions']['FLOOR_PLAN'])?
                            _.uniq(raw[':propertyOptions']['FLOOR_PLAN'].map(unit=> typeof unit.BEDS === 'string'? Number(unit.BEDS):unit.BEDS).sort()).filter(item=>!_.isNil(item) && item <= 3 ).map(item=>item===0?'Studio':item)
                            :
                            null

        bedsLine = bedsLine!==null?(hasLargerThanThree && !bedsLine.includes(3))?[...bedsLine,3]:bedsLine:null
        
        const processedData = {
            rawData:raw,
            processedData:{
                projectId:raw.id,
                name:listing.name,
                price:listing?.[':prices']?.['LIST']?.amount || null,
                priceM:listing?.[':prices']?.['LIST_M']?.amount || null,
                priceMarket:listing?.[':prices']?.['MARKET']?.amount ||null,
                showTextForPrice:listing.showTextForPrice,
                currency:listing?.[':prices']?.['LIST']?.currency,
                propertyType:raw?.[':propertyTypes'].map(item=>item?.[':type']?.name),
                displayName:raw.displayName,
                suburb:address.city,
                address:address,
                dispAddress:raw[':dispAddress']||null,
                coordinate:{
                    "latitude": address.latitude || -33.870846,  
                    "longitude": address.longitude || 151.20733
                },
                saleStatus:Boolean(listing.underOffer)?'underOffer':daysToNow(raw.createdAt)<7?'new':null,
                description:listing.desc,
                features:sellingPoints,
                // features:features?Object.keys(features).filter(key=>features[key][':type']).map(key=>features[key][':type'].name):[],
                isProject:true,
                firbQuota:raw.firbQuota,
                thumbnail:raw.logo,
                banners:banners,
                promotions:[], // need api information,
                nextAvailableInspectionTime:null, // merged from mergeAvailableInspectionTime()
                availableInspectionTimes:parsedOpenInspections, // merged from mergeAvailableInspectionTime()
                availablePrivateInspectionTime:[], // need api information,
                assignedAgents:assignedAgents,
                listingId:raw.listing,
                services:listing[':resourceGroup']?{
                    floorPlan:_.find(listing[':resourceGroup'][':resources'],{mediaTag:'FLOOR_PLAN'})?.url,
                    VR:_.find(listing[':resourceGroup'][':resources'],{mediaTag:'VRTOUR'})?.url,
                    VRList:_.filter(listing[':resourceGroup'][':resources'],{mediaTag:'VRTOUR'}),
                }:{},
                constructionUpdates:listing[':resourceGroup']?listing[':resourceGroup']?.[':resources'].filter(item=>item.mediaTag === 'DOC'):[],
                units:processedUnits,
                newApartment:daysToNow(raw.createdAt)<30,
                // bedRooms:bedsLine?`${_.join(bedsLine,', ')} bed${Number(_.last(bedsLine))>1?'s':''} available`:'',
                bedRooms:bedsLine?`${_.join(bedsLine,', ')}${hasLargerThanThree?'+':''}`:'',
                // bedRooms:'',
                highlightTags:listing.highlightTags?_.split(listing.highlightTags,','):[],
                developer:raw?.[':developer']?.name,
                completeDate:_.isNil(raw.expectedSettlingDate)?null:raw.expectedSettlingDate, 
                transport:null,
            }
        }

        // const mergedProcessedData = this.mergeAvailableInspectionTime(processedData,parsedOpenInspections)
        return processedData
    }

    processPropertyDetailResults = (raw)=>{
        
        const listing = raw[':listing']
        const attributes = raw[':attributes']
        const address = raw[':address']
        const features = raw[':features'] || {};
        const sellingPoints = listing[':sellingPoints'] || []
        let banners = (listing[':resourceGroup'] && listing[':resourceGroup'][':resources']) ? this.processBanners(listing[':resourceGroup'][':resources']):[]
        banners = banners.length===0?this.defaultBanner:banners
        const videoIndex = _.findIndex(banners,'video')
        if(videoIndex>=0){
            let reorderBanners = banners.filter(banner=>!banner.video)
            //banners[videoIndex].video = 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4'
            reorderBanners.splice(1,0,banners[videoIndex])
            banners = reorderBanners
        }

        const assignedAgents = listing[':assignedAgents'] && listing[':agency'] ?listing[':assignedAgents'].filter(agent=>Boolean(agent.isListed)).map(item=>{
            item[':agent'][':agency'] = listing[':agency']
            return item[':agent']
        }):[]
        const precessedAttributes = _.reduce(attributes, (result, attr, key) => {
            let name = key;
            switch (key) {
                case 'BATH':
                    name = 'bathrooms'
                    break;
                case 'BEDS':
                    name = 'bedrooms'
                    break;
                case 'CNCL':
                    name = 'council'
                    break;
                case 'PARK':
                    name = 'carspaces'
                    break;
                case 'TSIZE':
                    name = 'totalSize'
                    break;
                case 'ISIZE':
                    name = 'internalSize'
                    break;
                case 'ESIZE':
                    name = 'externalSize'
                    break;
                case 'WATER':
                    name = 'water'
                    break;
                case 'STRATA':
                    name = 'strata'
                    break;
                case 'ASPECT':
                    name = 'aspect'
                    break;
                case 'CLSCM':
                    name = 'colourScheme'
                    break;
                case 'MED_STD':
                    name = 'mediaOrStudy'
                    break;
                case 'STOR':
                    name = 'storage'
                    break;
                case 'ADAP':
                    name = 'adaptable'
                    break;
            }
            result[name] = attr.value;
            return result;
        }, {});
        const parsedOpenInspections = listing[":openInspections"]?this.generalInspectionStore.generateOpenInspectionTimeList(listing[':openInspections']):[]
        
        const processedData =  {
            rawData:raw,
            processedData:{
                propertyId:raw.id,
                name:listing.name,
                price:listing?.[':prices']?.['LIST']?.amount || null,
                priceM:listing?.[':prices']?.['LIST_M']?.amount || null,
                priceMarket:listing?.[':prices']?.['MARKET']?.amount ||null,
                showTextForPrice:listing.showTextForPrice,
                currency:listing?.[':prices']?.['LIST']?.currency,
                propertyType:raw?.[':type']?.name,
                suburb:address?.city,
                ...precessedAttributes,
                address:address,
                displayName:raw.displayName,
                coordinate:{
                    "latitude": address?.latitude || -33.870846, 
                    "longitude": address?.longitude || 151.20733
                },
                saleStatus:Boolean(listing.underOffer)?'underOffer':daysToNow(raw.createdAt)<7?'new':null,
                description:listing.desc,
                // features:features?Object.keys(features).filter(key=>features[key][':type']).map(key=>features[key][':type'].name):[],
                features:sellingPoints,
                isProject:false,
                isLeasing:listing.mainCategory===300,
                thumbnail:banners.length>0?banners[0].thumbnailMobile:null,
                banners:banners,
                promotions:[], // need api information,
                nextAvailableInspectionTime:this.openInspectionString(parsedOpenInspections),
                availableInspectionTimes:parsedOpenInspections,
                availablePrivateInspectionTime:[], // need api information,
                assignedAgents:assignedAgents,
                listingId:raw.listing,
                agencyName:raw?.[':agency']?.name,
                highlightTags:listing.highlightTags?_.split(listing.highlightTags,','):[],
                services:listing[':resourceGroup']?{
                    floorPlan:_.find(listing[':resourceGroup'][':resources'],{mediaTag:'FLOOR_PLAN'})?.url,
                    VR:_.find(listing[':resourceGroup'][':resources'],{mediaTag:'VRTOUR'})?.url,
                    VRList:_.filter(listing[':resourceGroup'][':resources'],{mediaTag:'VRTOUR'}),
                }:{}
            }
        } 
        return processedData
    }

    processFakeData = (data) =>{
        let list = []
        data.forEach(e => {
            if(e.isProject){
                list = _.concat(list,e.projects.map(p=>{
                    p.isProject=true 
                    return p
                }))
            }else{
                list = _.concat(list,e.properties)
            }
        });
        return list 
    }


}

export default PropertyStore;