/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const ReactDOM = require('react-dom')

const {Provider} = require('mobx-react')
const stores = require('./src/stores')
const React = require('react')
const { ThemeProvider,withStyles,useTheme,StylesProvider,createGenerateClassName } = require('@material-ui/core/styles')
const {CssBaseline} = require('@material-ui/core')
const {theme} = require('./src/getPageContext')
const {SnackbarProvider} = require('notistack')
const {ReactComponent:Confirm } = require('@src/assets/svgIcons/Confirm.svg')
const {ReactComponent:Info } = require('@src/assets/svgIcons/Info 1.svg')
const {ReactComponent:Close } = require('@src/assets/svgIcons/Close.svg')
const generateClassName = createGenerateClassName();



exports.replaceHydrateFunction = ()=> {
    return (element, container, callback) => {
        ReactDOM.render(element, container, callback)
    }
}

const CustomSnackbarProvider = withStyles(theme=>({
    root:{
        backgroundColor:'white',
        
    },
    variantSuccess:{
        color:'black !important',
        fontWeight:500,
        backgroundColor:'white !important',
    },
    variantError:{
        color:'black !important',
        fontWeight:500,
        backgroundColor:'white !important',
    },
    variantWarning:{
        color:'black !important',
        fontWeight:500,
        backgroundColor:'white !important',
    },
    variantInfo:{
        color:'black !important',
        fontWeight:500,
        backgroundColor:'white !important',
    }
}))(SnackbarProvider)

exports.wrapRootElement = ({element})=>{
    require('./icons')
    return (
        <Provider {...stores}>
            <CustomSnackbarProvider
                autoHideDuration={3000}
                iconVariant={{
                    success: <Confirm height={25} width={25} fill={theme.palette.primary.main} style={{marginRight:8}}/>,
                    error: <Close height={25} width={25} fill={theme.palette.error.main} style={{marginRight:8}}/>,
                    warning: <Info height={25} width={25} fill={theme.palette.alert.main} style={{marginRight:8}}/>,
                }}
            >
                {element}
            </CustomSnackbarProvider>
        </Provider>
    )
}

exports.onClientEntry = () => {
    // Load Roboto font to support Material Design
    const pathRoboto =
        'https://fonts.googleapis.com/css?family=Roboto:300,400,500';
    const linkRoboto = document.createElement('link');
    linkRoboto.setAttribute('rel', 'stylesheet');
    linkRoboto.setAttribute('href', pathRoboto);
    document.head.appendChild(linkRoboto);

    // Load Material Icons
    const pathIcons = 'https://fonts.googleapis.com/icon?family=Material+Icons';
    const linkIcons = document.createElement('link');
    linkIcons.setAttribute('rel', 'stylesheet');
    linkIcons.setAttribute('href', pathIcons);
    document.head.appendChild(linkIcons);
};
