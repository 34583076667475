const codeTableCache = {};

class Api {
    constructor(restClient, fndEndpoint) {
        this.restClient = restClient;
        this.fndEndpoint = fndEndpoint;
    }

    getUserProfile_ = async () => {
        const result = await this.restClient.get('user-profile');
        return result.response;
    };

    updateUserProfile_ = async data => {
        const result = await this.restClient.post('user-profile', data);
        return result;
    };

    getAgentContacts_ = async search => {
        const result = await this.restClient.get('agent-contact', { search });
        return result.response;
    };

    searchAgents_ = async search => {
        const result = await this.restClient.get('agent', { search });
        return result.response;
    };

    getAgentDetail_ = async agentId => {
        const result = await this.restClient.get(['agent', agentId]);
        return result.response;
    };

    getAgencyDetail_ = async agencyId => {
        const result = await this.restClient.get(['agency', agencyId]);
        return result.response;
    };

    getAgencies_ = async () => {
        const result = await this.restClient.get('agency');
        return result.response;
    };

    getAgencyAgents_ = async agencyId => {
        const result = await this.restClient.get(['agency', agencyId, 'agent']);
        return result.response;
    };

    checkAgentsAsContact = async agentIdList => {
        const result = await this.restClient.post(['agent', 'as-contact'], {
            agentIdList,
        });
        return result.response;
    };

    getContacts_ = async () => {
        const result = await this.restClient.get('contact');
        return result.response;
    };

    addContact_ = async (targetUserId, extra) => {
        const result = await this.restClient.post('contact', {
            ...extra,
            contact: targetUserId,
        });
        return result.response;
    };

    getMessageFeeds_ = async () => {
        const result = await this.restClient.get('message-feed');
        return result.response;
    };

    deleteChatGroup_ = async groupId => {
        const result = await this.restClient.del(['message-feed', groupId]);
        return result.response;
    };

    getChatGroup_ = async targetUserId => {
        const result = await this.restClient.get([
            'chat-group-with',
            targetUserId,
        ]);
        return result.response;
    };

    getGroupMessages_ = async (groupId, beforeMessageId, afterMessageId) => {
        const query = {
            groupId,
        };

        if (beforeMessageId != null) {
            query.beforeMessageId = beforeMessageId;
        }

        if (afterMessageId != null) {
            query.afterMessageId = afterMessageId;
        }

        const result = await this.restClient.get('message', query);
        return result.response;
    };

    sendMessage_ = async (groupId, msg) => {
        const result = await this.restClient.post('message', {
            groupId,
            msg,
        });
        return result.response;
    };

    startChat_ = async (receiverId, msg) => {
        const result = await this.restClient.post('message', {
            receiverId,
            msg,
        });
        return result.response;
    };

    getProperties_ = async filter => {
        const result = await this.restClient.post('property-search', filter);
        return result.response;
    };

    getProperty_ = async propertyId => {
        const result = await this.restClient.get(['property', propertyId]);
        return result.response;
    };

    getProject_ = async projectId => {
        const result = await this.restClient.get(['project', projectId]);
        return result.response;
    };

    getClients_ = async () => {
        const result = await this.restClient.get('client');
        console.log('testing: ', result);
        return result.response;
    };

    getPropertyFeatures = async () => {
        const result = await this.restClient.get(['enumeration', 'PROTY_FEAT']);
        return result.response;
    };

    getPropertyTypes = async () => {
        const result = await this.restClient.get(['enumeration', 'PROTY_TYPE']);
        return result.response;
    };

    getPropertyOpenInspection_ = async listingId => {
        const result = await this.restClient.get(
            `listing/${listingId}/open-inspection`,
        );
        return result.response;
    };

    async getAndCache_(key, apiPath, query, options) {
        let codeTable = codeTableCache[key];
        if (!codeTable) {
            const { response } = await this.restClient.get(
                apiPath,
                query,
                options,
            );
            codeTableCache[key] = codeTable = response;
        }

        return codeTable;
    }

    saveAListingToCollection_ = async (listingId, labels) => {
        const result = await this.restClient.post('collection', {
            listing: listingId,
            labels: labels,
        });
        return result.response;
    };

    bookPrivateInspection_ = async (listingId, timeObj) => {
        const result = await this.restClient.post(
            ['listing', listingId, 'inspection-booking'],
            timeObj,
        );
        return result.response;
    };

    addOpenInspection_ = async (listingId, timeObj) => {
        const result = await this.restClient.post(
            ['listing', listingId, 'open-inspection'],
            timeObj,
        );
        return result.response;
    };

    delAListingFromCollection_ = async listingId => {
        const result = await this.restClient.del(['collection', listingId]);
        return result.response;
    };

    codeTable_ = type => this.getAndCache_(type, ['enumeration', type]);

    getStreetTypes_ = () =>
        this.getAndCache_('_ST', 'street-type', null, {
            endpoint: this.fndEndpoint,
        });
    getStates_ = () =>
        this.getAndCache_('_STATE', ['country', 'AU', 'state'], null, {
            endpoint: this.fndEndpoint,
        });

    getCollection_ = async () => {
        const result = await this.restClient.get('collection');
        return result.response;
    };

    getCollectionByType_ = async type => {
        const result = await this.restClient.get('collection', { type });
        return result.response;
    };

    getCollectionWithFilter_ = async filter => {
        const result = await this.restClient.get('collection', filter);
        return result.response;
    };

    saveListingToCollection_ = async itemId => {
        const result = await this.restClient.post('collection', {
            listing: itemId,
            labels: [],
        });
        return result.response;
    };

    deleteListingFromCollection_ = async itemId => {
        const result = await this.restClient.del(['collection', itemId]);
        return result.response;
    };

    deleteAgenda_ = async agendaId => {
        const result = await this.restClient.del(['agenda', agendaId]);
        return result.response;
    };

    getAgendas = async () => {
        const result = await this.restClient.get('agenda');
        return result.response;
    };

    getOssSignature_ = async (entity, id) => {
        const result = await this.restClient.post(
            'oss-policy-signature',
            {
                entity,
                id,
            },
            null,
            { endpoint: this.fndEndpoint },
        );

        return result;
    };

    changePassword_ = async (body) => {

        const result = await this.restClient.post(
            'update-password',
            body,
            null,
            { endpoint: this.fndEndpoint },
        );

        return result
    }

    uploadToOss_ = async (ossData, objKey, uri) => {
        await this.restClient.upload('', { uri, name: objKey }, null, null, {
            endpoint: ossData.host,
            formData: {
                key: objKey,
                OSSAccessKeyId: ossData.accessId,
                policy: ossData.policy,
                Signature: ossData.signature,
            },
        });

        return { url: ossData.host + '/' + objKey };
    };

    addClientInterAction_ = async (clientId, listingId, body) => {
        const result = await this.restClient.post(
            ['client', clientId, 'listing', listingId],
            body,
        );
        return result.response;
    };

    sendListingEnquiry_ = async (listingId, agentId) => {
        const result = await this.restClient.post(['listing', listingId, 'enquiry'], {
            agent: agentId
        })
        return result.response
    }

    addNote_ = async (note) => {
        const result = await this.restClient.post('user-note', note);
        return result.response;
    };

    updateNote_ = async (note) => {
        const result = await this.restClient.put(['user-note', note.id], note);
        return result.response;
    };

    deleteNote_ = async (noteId) => {
        const result = await this.restClient.del(['user-note', noteId]);
        return result.response;
    };

    getNotes_ = async () => {
        const result = await this.restClient.get('user-note');
        return result.response;
    };

    setPriceChangeNotification_ = async (listingId, priceChangeNotify) => {
        const result = await this.restClient.put(['collection', listingId], { priceChangeNotify })
        return result.response
    }

    getDeals_ = async (filter) => {
        const result = await this.restClient.get(['sales-in-progress'], filter);
        return result.response
    }

    getLeasings_ = async (filter) => {
        const result = await this.restClient.get(['leasing'], filter);
        return result.response
    }

    updateDeals_ = async (salesId, data) => {
        const result = await this.restClient.put(['sales-in-progress', salesId], data);
        return result.response
    }

    submitRegistration_ = async (salesId) => {
        const result = await this.restClient.put(['sales-in-progress', salesId, 'action', 'submitRegistration'],);
        return result.response
    }


    getDealDetail_ = async (id) => {
        const result = await this.restClient.get(['sales-in-progress', id]);
        return result.response
    }

    addCustomData_ = async (folder, data) => {
        const res = await this.restClient.post(['my-data', folder, 'item'], data)
        return res.response
    }

    getAllCustomData_ = async (folder, data) => {
        const res = await this.restClient.get(['my-data', folder, 'item'])
        return res.response
    }

    deleteCustomData_ = async (folder, key) => {
        const res = await this.restClient.del(['my-data', folder, 'item', key])
        return res.response
    }


    getSavedSearches_ = async () => {
        const result = await this.restClient.get(['my-data', 'saved-search', 'item']);
        return result.response;
    }

    deleteSavedSearch_ = async (id) => {
        const res = await this.restClient.del(['my-data', 'saved-search', 'item', id])
        return res.response;
    }

    forgetPasswordWithMobile_ = async (mobile) => {
        const res = await this.restClient.post('forget-password', { mobile });
        return res.response;
    }

    verifyCode_ = async (verifyId, code) => {
        const res = await this.restClient.get(['verify', verifyId], { code, reason: 'forgot' });
        return res.response;
    }


    forgetPasswordWithEmail_ = async (email) => {
        const res = await this.restClient.post('forget-password', { email });
        return res.response;
    }

    //verifyId is the id returned from forgetPasswordWithMobile_
    changePasswordWithCode_ = async (verifyId, code, password) => {
        const res = await this.restClient.post(['chnage-password', verifyId], { code, password, reason: 'forgot' });
        return res.response;
    }

    addNoteForCollection_ = async (id, note) => {
        const result = await this.restClient.put(['collection', id], { note });
        return result.response;
    };

    getCollectionDetail_ = async (id) => {
        const result = await this.restClient.get(['collection', id]);
        return result.response;
    };

    addOrUpdateSolicitorSaleInProgress_ = async (saleId, solicitor) => {
        const result = await this.restClient.post(['sales-in-progress', saleId, 'solicitor'], solicitor)
        return result.response
    }

    deleteSolicitorSaleInProgress_ = async (saleId, solicitorId) => {
        const result = await this.restClient.del(['sales-in-progress', saleId, 'solicitor', solicitorId])
        return result.response
    }

    updatePurchaserSaleInProgress_ = async (saleId, purchaserId, purchaser) => {
        const result = await this.restClient.put(['sales-in-progress', saleId, 'purchaser', purchaserId], purchaser)
        return result.response
    }

    deletePurchaserSaleInProgress_ = async (saleId, purchaserId) => {
        const result = await this.restClient.del(['sales-in-progress', saleId, 'purchaser', purchaserId])
        return result.response
    }

    getNearBySchool_ = async (latitude, longitude, radius = 5000, type = 'school') => {
        const result = await this.restClient.post(['place-near-by'], {
            latitude,
            longitude,
            radius,
            type
        }, null, { endpoint: this.fndEndpoint })
        return result.response
    }

    deleteContact_ = async userId => {
        const result = await this.restClient.del(['contact', userId])
        return result.response
    }

    getNearbyProperty_ = async (coordinator) => {
        const result = await this.restClient.post('property-search', {
            "mapCentre": coordinator,
            "listingType": "sale",
            "limit": 9
        })
        return result.response
    }

    getDeveloperDetail_ = async developerId => {
        const result = await this.restClient.get(['developer', developerId])
        return result.response
    }

    getPropertyByDeveloperId_ = async developerId => {
        const res = await this.restClient.post('property-search', {
            developer: developerId
        })
        return res.response
    }

    getNewProject_ = async () => {
        const result = await this.restClient.get('new-project', {
            "limit": 9
        })
        return result.response
    }

    getPropertyByPostcode_ = async (postcodeArray) => {
        const result = await this.restClient.post('property-search', {
            "postcode": postcodeArray,
            "limit": 6
        })
        return result.response
    }

    addDocument = async (saleId, url, fileName) => {
        const res = await this.restClient.post(['sales-in-progress', saleId, 'document'], { url, fileName });
        return res.response;
    }

    deleteDocument = async (saleId, documentId) => {
        const res = await this.restClient.del(['sales-in-progress', saleId, 'document', documentId]);
        return res.response;
    }

    addNoteForAgenda_ = async (id, note) => {
        const result = await this.restClient.put(['agenda', id], { note });
        return result.response;
    };

    heartBeat_ = async () => {
        const result = await this.restClient.get('heart-beat');
        return result.response
    }
    placeAutoComplete_ = async (str, sessionToken = null) => {
        const result = await this.restClient.post(['place-auto-complete'],
            {
                "input": str,
                "session": sessionToken
            },
            null,
            { endpoint: this.fndEndpoint });
        return result.response
    }

    getGeocoding_ = async (str) => {
        const result = await this.restClient.post(['geocoding'],
            {
                "input": str,
            },
            null,
            { endpoint: this.fndEndpoint });
        return result.response
    }

    initiateLeasingApplication_ = async (listingId, agentId) => {
        const result = await this.restClient.post(['leasing'], {
            listing: listingId,
            agent: agentId,
        });
        return result.response;
    };

    getAgencyTermsAndConditions_ = async agencyId => {
        const result = await this.restClient.get(['agency-terms', agencyId]);
        return result.response;
    };

    getLeasingDetail_ = async (id) => {
        const result = await this.restClient.get(['leasing', id])
        return result.response
    }

    fillLeasingApplication_ = async (dealId, data) => {
        const result = await this.restClient.put(['leasing', dealId], data);
        return result.response;
    };

    updateLeasingApplication_ = async (dealId, data) => {
        const result = await this.restClient.post(
            ['leasing', dealId, 'applicant'],
            data,
        );
        return result.response;
    };

    submitLeasingApplication_ = async dealId => {
        const result = await this.restClient.put([
            'leasing',
            dealId,
            'action',
            'submit',
        ]);
        return result.response;
    };

    addLeasingApplicationDocument_ = async (dealId, data) => {
        const result = await this.restClient.post(
            ['leasing', dealId, 'document'],
            data,
        );
        return result.response;
    };

    deleteLeasingApplicationDocument_ = async (dealId, documentId) => {
        const result = await this.restClient.del([
            'leasing',
            dealId,
            'document',
            documentId,
        ]);
        return result.response;
    };
}

export default Api;
